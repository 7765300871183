<template>
    <div>
        <div class="about-area ptb-95">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="text-center">Privacy Policy</h4>
                        <p class="mt-5 text-justify">This Privacy Policy manages the manner in which German Butcher collects, uses, maintains and discloses information collected from users of the germanbutcherbd.com website or any kind of platform such as mobile applications. This privacy policy is applicable for the Site with all products and services offered by German Butcher.</p>
                        <p class="text-justify">When you use our website, we collect and store your personal information which is provided by you. Our goal in doing so is to provide you a safe, efficient, smooth shopping experience. This allows us to provide services and features that most likely meet your needs, and to customize our website to make your experience safer and easier. More importantly, while doing so, we collect personal information from you that we consider necessary for achieving this purpose. </p>
                        <p class="text-justify"><strong>Here the ways in which we collect and store your information:</strong>  </p>
                        <p class="text-justify">We receive and store any information you enter on our website or give us in any other way. We use the information that you provide for such purposes as responding to your requests, customizing future shopping for you, improving our stores, and communicating with you.</p>

                         <p class="text-justify">We also store certain types of information whenever you interact with us. For example, like many websites, we use "cookies," and we obtain certain types of information when your web browser accesses germanbutcherbd.com or advertisements and other content served by or on behalf of germanbutcherbd.com on other websites. </p> 

                         <p class="text-justify">Information about our customers is an important part of our business, and we are not in the business of selling it to others. </p>
                         <p class="text-justify">We release account and other personal information when we believe release is appropriate to comply with the law; enforce or apply our Terms of Use and other agreements; or protect the rights, property, or safety of germanbutcherbd.com users, or others. This includes exchanging information with other companies and organizations for fraud protection.</p>
                    </div>
                    
                </div>
                
            </div>
        </div>

        <!-- <div style="margin-bottom: -80px" class="map-area">
            <GoogleMap />
        </div> -->
    </div>
</template>

<script>

    export default {
        name: "AboutComponent",
        components: {},
        metaInfo: {
          title: 'German Butcher ',
          titleTemplate: '%s - Privacy Policy',
          htmlAttrs: {
            lang: 'en',
            amp: true
          }
        },
        methods: {
            notify(){
                this.$notify({
                    group: 'foo',
                    title: 'Important message',
                    text: 'Hello user! This is a notification!'
                });
            },
            
        }, //methods end here
    }
</script>

<style scoped>
    
    .map-area {
        height: 350px;
        padding: 15px 0;
    }
    @font-face {
        font-family: myThirdFont;
        src: url('/fonts/Ubuntu-R.ttf');
    }
    *{
        font-family: myThirdFont;
    }
    .flex-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

</style>